import React from "react";
import { getPercentage, secondsToHourMins } from "TARGET_BUILD/common/utils/commonUtils";
import { getUtilizationTime } from "TARGET_BUILD/common/utils/commonUtils";
import { PieChartColor } from "TARGET_BUILD/common/utils/enums/styledEnum";
import { useTranslation } from "react-i18next";
import theme from "TARGET_BUILD/theme";
import ReactEcharts from "echarts-for-react";
import { IUtilizationSummaryProps } from "./model/iUtilizationDetailsView";
const utilizationSummaryChart = (props: IUtilizationSummaryProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  const { chartData } = props;
  const { utilizationSummary } = chartData;
  const { totalTime, engineOffTime, idleTime, workingTime } = getUtilizationTime(utilizationSummary);

  const getData = () => {
    const data = [
      {
        value: getPercentage(workingTime, totalTime),
        name: `${t("assets:WORKING_LABEL")}`,
        itemStyle: {
          color: PieChartColor.WORKING,
        },
        label: {
          show: true,
          formatter: "{workingCssClass|{d}%}",
        },
      },
      {
        value: getPercentage(engineOffTime, totalTime),
        name: `${t("assets:ENGINE_LABEL")}`,
        itemStyle: {
          color: PieChartColor.ENGINE_HOUR,
        },
        label: {
          show: true,
          formatter: "{engineCssClass|{d}%}",
        },
      },
    ];

    if (!utilizationSummary?.hideIdle) {
      data.splice(1, 0, {
        value: getPercentage(idleTime, totalTime),
        name: `${t("assets:IDLE_LABEL")}`,
        itemStyle: {
          color: PieChartColor.IDLE,
        },
        label: {
          show: true,
          formatter: "{idleCssClass|{d}%}",
        },
      });
    }
    return data;
  };

  const options = {
    tooltip: {
      show: false,
      trigger: "item",
    },
    animation: false,
    legend: {
      orient: "vertical",
      left: "10%",
      bottom: 1,
      icon: "rect",
      selectedMode: false,
      itemGap: 15,
      itemHeight: 30,
      textStyle: {
        fontWeight: theme.fontBold.fontWeight,
        fontFamily: theme.fontBold.fontFamily,
        lineHeight: 15,
        fontSize: theme.fontSize.medium,
        padding: [0, 0, 0, 8],
      },
      formatter: (name: string) => {
        switch (name) {
          case t("assets:WORKING_LABEL"):
            return name + secondsToHourMins(workingTime);
          case t("assets:IDLE_LABEL"):
            return name + secondsToHourMins(idleTime);
          case t("assets:ENGINE_LABEL"):
            return name + secondsToHourMins(engineOffTime);
          default:
            return "";
        }
      },
    },
    series: [
      {
        width: "85%",
        height: "85%",
        top: "-10%",
        name: t("assets:UTILIZATION_CHART"),
        type: "pie",
        radius: ["50%", "70%"],
        center: ["60%", "50%"],
        label: {
          rich: {
            workingCssClass: {
              color: PieChartColor.WORKING,
              fontWeight: theme.fontBold.fontWeight,
              fontFamily: theme.fontBold.fontFamily,
              fontSize: theme.fontSize.base,
            },
            idleCssClass: {
              color: PieChartColor.IDLE,
              fontWeight: theme.fontBold.fontWeight,
              fontFamily: theme.fontBold.fontFamily,
              fontSize: theme.fontSize.base,
            },
            engineCssClass: {
              color: PieChartColor.ENGINE_HOUR,
              fontWeight: theme.fontBold.fontWeight,
              fontFamily: theme.fontBold.fontFamily,
              fontSize: theme.fontSize.base,
            },
          },
        },
        silent: true,
        labelLine: {
          show: true,
          length: 8,
          length2: 4,
        },
        labelLayout: {
          hideOverlap: false,
          width: 50,
        },
        percentPrecision: 1,
        minShowLabelAngle: 0.01,
        stillShowZeroSum: false,
        avoidLabelOverlap: true,
        data: getData(),
      },
    ],
  };

  return <ReactEcharts option={options} style={{ width: "100%", height: "100%" }}></ReactEcharts>;
};
export default utilizationSummaryChart;
