import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { ISearchBarProps } from "../commonProps/iSearchBarProps";
import FilterContainer from "../filter/filterContainer";

/**
 * Styled component that is used as a search bar wrapper.
 */
const StyledSearchBar = styled("div")`
  display: flex;
  flex-basis: 100%;
  max-width: 100%;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.base.borderColor};
  background-color: ${(props) => props.theme.colors.base.heavyConcrete10};
  position: relative;
  min-height: 64px;
  .field-wrapper {
    margin-bottom: 0;
  }
  .searchbox-wrapper {
    padding-top: 6px;
    padding-bottom: 6px;
    .input {
      font-size: ${(props) => props.theme.fontSize.base};
    }
  }
  .multiSelectControl {
    .typeselect {
      height: 40px;
      & > div {
        font-size: ${(props) => props.theme.fontSize.base};
        &:last-child > .multiValueInput {
          height: 36px;
        }
      }
      .dropDownPlaceholder {
        padding: 8px 0;
      }
      &.selected .multivalue {
        line-height: 36px !important;
      }
      .menuClose,
      .menuOpen {
        width: 16px;
        height: 16px;
        svg {
          width: 16px;
          height: auto;
        }
      }
    }
  }
  .readonly-dropdown {
    height: 40px;
  }
`;

/**
 * SearchBar component is holding the search box and other filter components.
 */
class SearchBar extends React.PureComponent<ISearchBarProps> {
  searchRef;
  render() {
    return (
      <StyledSearchBar id="searchBarWrapper" className="search-bar" ref={(ref) => (this.searchRef = ref)}>
        {this.props.searchComponent}
        {this.props.customComponent}
        <FilterContainer
          {...this.props}
          refreshFilters={this.props.handleFilterChange}
          searchContainerRef={this.searchRef}
          isSearchComponentLoaded={!!this.props.searchComponent}
        />
      </StyledSearchBar>
    );
  }
}

export default withTranslation()(SearchBar);
